import {InspirationItemFieldsFragment, InspirationSectionFieldsFragment} from '../../../../__generated__/datocms.types'
import {InspirationItem, InspirationSection} from '../types/inspiration-section.types'
import {parseSystemColour, validateDatoCmsTypes} from './common.serde'
import {parseAsset2} from './asset.serde'
import {parseCallToAction} from './call-to-action.serde'

export function parseInspirationSection(section?: InspirationSectionFieldsFragment): InspirationSection | undefined {
  if (!section) {
    return undefined
  }
  return {
    __type: 'InspirationSection',
    id: section.id,
    title: section.title!,
    plot: section.plot,
    description: section.description,
    items: parseInspirationSectionItemCollection(section.items),
  }
}

function parseInspirationSectionItemCollection(items: InspirationItemFieldsFragment[]): InspirationItem[] {
  return items.map(item => parseInspirationSectionItem(item)) ?? []
}

function parseInspirationSectionItem(e: InspirationItemFieldsFragment): InspirationItem {
  const entry = validateDatoCmsTypes(e)
  return {
    title: entry.title,
    description: entry.description,
    background: parseAsset2(entry.background),
    callToAction: parseCallToAction(entry.callToAction),
    chip:
      entry.chipText && entry.chipColor
        ? {
            text: entry.chipText,
            colour: parseSystemColour(entry.chipColor)!,
          }
        : undefined,
  }
}
