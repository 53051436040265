import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {InspirationItem, InspirationSection} from '../../../../core/content/types/inspiration-section.types'
import {ApplicationRoute} from '../../../../craft/utils/application.route'
import {ButtonModule} from '../../../../craft/button/button.module'
import {TeaserCardComponent} from '../../../../craft/cards/teaser-card/teaser-card.component'
import {ChipComponent} from '../../../../craft/chip/chip.component'
import {TranslocoDirective} from '@ngneat/transloco'
import {TeaserCardTitleComponent} from '../../../../craft/cards/teaser-card/teaser-card-title/teaser-card-title.component'
import {ContentSectionComponent} from '../../../../craft/content-section/content-section.component'

@Component({
  selector: 'bgo-inspiration-section',
  templateUrl: './inspiration-section.component.html',
  styles: [':host { display: block }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ContentSectionComponent,
    ButtonModule,
    TeaserCardComponent,
    TeaserCardTitleComponent,
    ChipComponent,
    TranslocoDirective,
  ],
  standalone: true,
})
export class InspirationSectionComponent {
  @Input() inspirationSection!: InspirationSection

  identifier(item: InspirationItem): string | undefined {
    const cta = item.callToAction.value
    const path =
      cta instanceof ApplicationRoute ? cta.pathWithoutLocalizedRouteToken : item.callToAction?.value.toString() ?? ''
    return `inspiration-article-${path.split('/').pop()}`
  }
}
