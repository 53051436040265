<div *transloco="let t">
  <cft-content-section
    [sectionId]="inspirationSection.id"
    [plot]="inspirationSection.plot"
    [title]="inspirationSection.title"
    titleAlignment="left"
  >
    <div class="grid-4-cols-teaser-cards">
      @for (item of inspirationSection.items; track item) {
        <cft-call-to-action [callToAction]="item.callToAction" callToActionStyle="content">
          <cft-teaser-card [attr.id]="identifier(item) + '-card'" [image]="item.background">
            @if (item.chip) {
              <cft-chip [colour]="item.chip.colour">{{ item.chip.text }}</cft-chip>
            }
            <cft-teaser-card-title>{{ item.title }}</cft-teaser-card-title>
            <div>{{ item.description }}</div>
          </cft-teaser-card>
        </cft-call-to-action>
      }
    </div>
  </cft-content-section>
</div>
